<template>
  <div class="addProjectsafe">
    <div class="addProjects-wrap">
      <div class="addProjects-top">
        <div>{{addPtitle}}</div>
        <img @click="closefn"
             src="@/assets/images/pop01.png"
             alt="" />
      </div>
      <div class="addProjects-content">

        <div class="addProjects-input">
          <span>大事记名称</span>
          <div>
            <el-input v-model="inputs1"
                      maxlength='20'
                      placeholder="请输入名称"></el-input>
          </div>
        </div>

      </div>

      <div class="addProjects-content">

        <div class="addProjects-input">
          <span>封面图</span>
          <div class="img-list">

            <el-upload class="picture-card"
                       :multiple="true"
                       :before-upload="beforeUpload"
                       accept="image/jpg,image/jpeg,image/png"
                       :action="
              baseUrl + `/Intelligence_Building_API/WeatherForecast/SavePictureList?type=${sqtype==1?5:9}`
            "
                       :on-success="successfn"
                       :class="{ hiddenBtn: filelist.length >= 1 }"
                       :file-list="filelist"
                       list-type="picture-card"
                       :on-preview="handlePictureCardPreview"
                       :on-remove="handleRemove"
                       :limit="1">
              <img src="@/assets/images/common/cicon09.png"
                   alt=""
                   class="up-icon">

            </el-upload>

          </div>
          <div class="list-title">(图片最大不超过3M)</div>
        </div>

      </div>

      <div class="addProjects-content">

        <div class="addProjects-input addProjects-input02">
          <span>大事记详情</span>
          <div>
            <div id="div1"
                 style="height:100%"></div>
          </div>
        </div>

      </div>

      <div class="addProjects-bottom">
        <button @click="closefn">取消</button>
        <button @click="AddExtractApplyfn"
                :plain="true"
                class="btns"
                :class="(!inputs1||!newHtml || !filelist.length)?'def':'' ">
          确定
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, onBeforeUnmount } from "vue";
import { ElMessage } from "element-plus";
import { getStorage } from "@/js/common";
import Ewang from "wangeditor";
import { baseUrl } from "@/js/util.js";

import { UpdateSecurityBigEvent, GetSecurityBigEvent } from '@/js/safeDisclose'
export default {
  props: {
    addPfalse: Boolean,
    bonus: Number,
    addPtitle: String,
    editdata: Object,
    sqtype: Number,
  },
  setup (props, { emit }) {
    const state = reactive({
      newHtml: '',
      sqtype: '',
      editor: null,
      baseUrl: baseUrl,
      inputs1: "", //
      inputs2: "",
      inputs3: "",
      value: "",
      Primarydata: [],
      Primaryobj: null,
      adding: false,
      addPtitle: '',
      editid: '',
      num: 0,
      showstyle: false,
      filelist: [],
      fileimg: null,
      tableDatas: [
        {
          inputs1: "", //
          inputs2: "",
        }
      ],
    });
    const closefn = () => {
      emit("addPfalsefn");
    };

    const cdata = getStorage("bscdata");

    const methods = {
      // 富文本详情
      edfn: () => {
        state.editor = new Ewang("#div1");
        // 配置 server 接口地址
        state.editor.config.uploadImgServer = `${state.baseUrl}/Intelligence_Building_API/WeatherForecast/SavePictureList?type=${state.sqtype == 1 ? 5 : 9}`;
        // 默认情况下，显示所有菜单
        state.editor.config.menus = [
          "head",
          "bold",
          "fontSize",
          "fontName",
          "italic",
          "underline",
          "strikeThrough",
          "indent",
          "lineHeight",
          "foreColor",
          "backColor",
          "link",
          "list",
          "justify",
          "quote",
          "emoticon",
          "image",
          // "video",
          "table",
          "code",
          "splitLine",
          "undo",
          "redo",
        ];
        state.editor.config.onchange = function (newHtml) {
          // console.log("change 之后最新的 html", newHtml);
          state.newHtml = newHtml
        };

        state.editor.config.uploadImgHooks = {
          fail: (xhr, editor, result) => {
            console.log(result);
            // 插入图片失败回调
          },
          success: (xhr, editor, result) => {
            // 图片上传成功回调
            console.log(result);
          },
          timeout: (xhr, editor) => {
            console.log(result);
            // 网络超时的回调
          },
          error: (xhr, editor) => {
            // 图片上传错误的回调
            console.log(result);
          },
          customInsert: (insertImg, result, editor) => {
            console.log(result);
            // 图片上传成功，插入图片的回调
            //result为上传图片成功的时候返回的数据，这里我打印了一下发现后台返回的是data：[{url:"路径的形式"},...]

            //insertImg()为插入图片的函数
            //循环插入图片
            for (let i = 0; i < result.length; i++) {
              // console.log(result)
              let url = `${state.baseUrl + result}`;
              //这里插一句, 如果项目在本地开发的,图片在服务器存着的
              //在编辑器里面回显的话url要加上ip,ip是看你们开发的时候怎么拿的,
              //我们是store存的直接拿来拼接上的
              insertImg(url);
            }
          },
        };

        state.editor.create();
      },
      // 图片
      beforeUpload: (file) => {

        const is1M = file.size / 1024 / 1024 < 3; // 限制小于3M

        if (!is1M) {
          ElMessage({
            showClose: true,
            message: '大小不可超过3M',
            type: "error",
          });

        }
        return is1M
      },
      // 上传图片
      successfn: (response, file, fileList) => {// 图片上传成功
        state.updatalistnum++;
        console.log(response, file, fileList);
        state.filelist = fileList;
        state.fileimg = response.join(',')
        state.imglist = [];
      },
      handlePictureCardPreview: (file) => {// 图片预览 没写功能
        console.log(file, file.url);
        state.dialogImageUrl = file.url;
        state.dialogVisible = true;
      },
      handleRemove: (file, fileList) => {// 删除图片
        state.updatalistnum++;
        let datas = [...file.response];
        state.filelist = fileList;
        state.imglist = [];
        // DeleteFile(datas);
      },

      // 添加项目大事记
      UpdateSecurityBigEvent: () => {
        let datas = {}
        if (state.addPtitle == '编辑大事记') {
          datas = {
            "id": state.editid,

            "title": state.inputs1,
            "content": state.editor.txt.html(),
            "imgUrl": state.filelist[0].response[0],
            "createdUser": cdata.uid,
            "projectID": cdata.pid,
            "type": state.sqtype,
          }

        } else {
          datas = {

            "title": state.inputs1,
            "content": state.editor.txt.html(),
            "imgUrl": state.fileimg,
            "createdUser": cdata.uid,
            "type": state.sqtype,
            "projectID": cdata.pid,
          }

        }

        console.log(datas, 'datas', state.filelist.join(','), state.filelist[0].response[0])

        UpdateSecurityBigEvent(datas).then((res) => {

          console.log(res);
          if (res.data.Code == 1) {
            ElMessage({
              showClose: true,
              message: res.data.Message,
              type: "success",
            });
            emit("addPfalsefn");
            emit("GetProjectBigEventList");
          } else {
            ElMessage({
              showClose: true,
              message: res.data.Message,
              type: "error",
            });
          }
        });

      },

      AddExtractApplyfn: () => {

        if (!state.inputs1 || !state.newHtml || !state.filelist.length) {
          return
        }

        methods.UpdateSecurityBigEvent()

      },
      // 项目大事记详情
      GetProjectBigEvent: () => {
        let datas = {
          ID: state.editid
        }

        console.log(datas, 'datas')

        GetSecurityBigEvent(datas).then((res) => {

          console.log(res);
          if (res.data.Code == 1) {
            state.inputs1 = res.data.Data.data.Title;
            state.editor.txt.html(res.data.Data.data.Content);
            // state.filelist= res.data.Data.data.ImgUrl.split(',')

            let obj = {
              name: res.data.Data.data.ID,
              url: baseUrl + res.data.Data.data.ImgUrl,
              response: [res.data.Data.data.ImgUrl],
            };
            state.filelist.push(obj);
            console.log(state.filelist, 1212)
          } else {
            ElMessage({
              showClose: true,
              message: res.data.Message,
              type: "error",
            });
          }
        });

      },

    };

    onMounted(() => {
      console.log(props)
      state.sqtype = props.sqtype;
      state.addPtitle = props.addPtitle;
      if (state.addPtitle == '编辑大事记') {

        state.editid = props.editdata.ID;
        methods.GetProjectBigEvent()
      }

      methods.edfn();
    });
    onBeforeUnmount(() => {
      state.editor.destroy();
      state.editor = null;
    });
    return {
      ...methods,
      closefn,
      ...toRefs(state),
    };
  },
};
</script>
<style lang="scss" scoped>
.addProjectsafe {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  .addProjects-wrap {
    width: 656px;
    min-height: 235px;
    background: #ffffff;
    border-radius: 4px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    .addProjects-top {
      padding: 0 20px;
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #e3edf8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #666666;
      img {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
    }
  }

  .add-wrap {
    max-height: 500px;
    overflow-y: auto;
  }

  .addProjects-content {
    width: 100%;
    padding: 0 20px 20px;
    // border-bottom: 1px solid #e3edf8;
    box-sizing: border-box;

    display: flex;
    justify-content: left;
    align-items: center;
    .annotation {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #00a5ff;
      text-align: center;
      margin-top: 5px;
      margin-left: 20px;
      box-sizing: border-box;
    }
    .addProjects-input {
      display: flex;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #555555;
      margin-top: 20px;
      box-sizing: border-box;
      align-items: center;
      position: relative;
      &:deep(.img-list) {
        width: 81px;
        height: 63px;
        .el-upload-list--picture-card {
          .el-upload-list__item {
            width: 81px;
            height: 63px;
            border-radius: 0;
          }
          .el-upload-list__item-actions:hover
            span.el-upload-list__item-preview {
            display: none;
          }
        }
        .el-upload--picture-card {
          width: 81px;
          height: 63px;
          position: relative;
          background: #f3f3f3;
          border: 1px dashed #999999;
          border-radius: 0;
          .up-icon {
            width: 34px;
            height: 35px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          i {
            position: absolute;
            right: 12px;
            bottom: 8px;
            font-size: 13px;
            color: #999999;
            display: inline-block;
            height: 20px;
            line-height: 20px;
          }
        }

        .hiddenBtn {
          .el-upload--picture-card {
            display: none;
          }
        }
      }
      .list-title {
        position: absolute;
        left: 186px;
        bottom: 2px;
      }

      span {
        display: inline-block;
        width: 90px;
        padding-right: 11px;
        box-sizing: border-box;
        text-align: right;
      }
      div {
        width: 337px;
      }
    }
    .addProjects-input02 {
      width: 100%;
      div {
        width: 500px;
      }

      #div1 .w-e-toolbar {
        z-index: 100 !important;
      }
      #div1 .w-e-text-container {
        z-index: 99 !important;
        height: 150px !important;
      }
    }
    .dels {
      width: 30px;
      height: 30px;
      display: flex;
      margin-top: auto;
    }

    .addProjects-input1 {
      margin: 20px 20px 0;
      box-sizing: border-box;
      div {
        width: 100px;
      }
    }
  }

  .addProjects-bottom {
    text-align: right;
    padding: 10px 20px;
    box-sizing: border-box;
    border-top: 1px solid #c6c6c6;
    button {
      display: inline-block;
      width: 68px;
      height: 36px;
      border-radius: 4px;
      border: 1px solid #c6c6c6;
      line-height: 36px;
      text-align: center;
      font-size: 14px;
      font-family: MicrosoftYaHei;

      margin-left: 10px;
      box-sizing: border-box;
      cursor: pointer;

      &:nth-child(2) {
      }
    }

    .btns {
      background: #027aff;
      color: #fff;
    }

    .def {
      background: rgb(239, 239, 239);
      color: #adbaae;
      cursor: default;
    }
  }
}
</style>